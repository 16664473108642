import React, { useState } from 'react'
import { images } from '../../constants'

import './Footer.css'

const Footer = () => {

  const [Active, setActive] = useState("home");

  return (
    <div className='app__footer'>
      <div className='app__footer-logo'>
       <img src={images.logo} alt='logo'/>
      </div>
      <div className='app__footer-links'>
        {['home', 'services', 'export', 'construction', 'contact us'].map((item, index) => (
            <a key={"link-" + item} href={"#" + item} className={(Active === item) ? "active" : ""} onClick={(e) => {setActive(e.target.innerHTML);}}>  
              <li>{item}</li>
            </a>
        ))}
      </div>
      <div className='app__footer-copyright'>
       <p>© Nushewer Engineering & Trading</p>
      </div>
    </div>
  )
}

export default Footer