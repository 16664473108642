
import './App.css';
import { Navbar } from './components';
import { About, Skills, Service, Coffee, ImportExport, Const, Construction, Contact, Footer } from './containers';


function App() {
  return (
    <div className="App">
      <Navbar />
      <About />
      <Skills />
      <Service />
      <Coffee />
      <ImportExport />
      <Const />
      <Construction />
      <Contact />
      <Footer />  
    </div>
  );
}

export default App;
