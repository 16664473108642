import React from 'react'
import { motion } from 'framer-motion'
import { images } from '../../constants'
import AppWrap from '../wrapper/AppWrap'
import { FaSquarePhone, FaLocationDot } from "react-icons/fa6";
import { AiFillMail } from "react-icons/ai";

import './Contact.css'

const Contact = () => {
  
  return (
    <div className='app__contact'>
      <div className='app__contact-info'>
        <motion.h1 className='title' whileInView={{x: [-100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
          Get In <span>Touch</span><div /><div />
        </motion.h1>
       
        <motion.div className='app__contact-info-list' whileInView={{x: [-100, 0], opacity: [0, 1]}} transition= {{duration : 1, delayChildren: 0.5, ease: 'easeInOut'}}>
  
        <li><div><FaSquarePhone /></div> &nbsp; +251 911 53 06 99</li>
        <li><div><FaSquarePhone /></div> &nbsp; +251 912 09 51 38</li>
        <li><div><FaSquarePhone /></div> &nbsp; +251 919 66 92 63</li>
        <li><div><AiFillMail /></div> &nbsp;&nbsp; <a href='mailto:info@nushewerengineering.com'>info@nushewerengineering.com</a></li>
        <li><div><FaLocationDot /></div> &nbsp; Nifas silk lafto Addis Ababa, Ethiopia.</li>
        </motion.div> 
      </div>
      <motion.div className='app__contact-image' whileInView={{x: [100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
        <img src={images.contact} alt={"image-" + images.contact} />
      </motion.div>  

    </div>
  )
}

export default AppWrap(Contact, 'contact us', 'app__container')