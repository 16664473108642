import logo from '../assets/logo.png';
import about from '../assets/about.png';
import skill from '../assets/skills.jpg';
import imports from '../assets/import.png';
import exports from '../assets/export.png';
import construction from '../assets/construction.png';
import building from '../assets/building.jpg';
import road from '../assets/road.jpg';
import bridge from '../assets/bridge.jpg';
import sewerage from '../assets/sewerage.jpg';
import water_works from '../assets/water_works.jpg';
import coffee from '../assets/coffee.jpg';
import aluminum from '../assets/aluminum.jpg';
import leather from '../assets/leather.jpg';
import contact from '../assets/contact.png';


const images = {
    logo,
    about,
    skill,
    imports,
    exports,
    construction,
    building,
    road,
    bridge,
    sewerage,
    water_works,
    coffee,
    aluminum,
    leather,
    contact
}

export default images