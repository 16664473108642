import React from 'react'
import { motion } from 'framer-motion'
import AppWrap from '../wrapper/AppWrap'
import './Coffee.css'

const Coffee = () => {
  return (
    <motion.div className='app__coffee app__flex app__bg-dark' whileInView={{ opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
      <div className=' app__coffee-slog'>
        <motion.h1 whileInView={{y: [-100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
          Our <span>Business Scope</span>
        </motion.h1>
        <motion.p whileInView={{y: [100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
          We Export The Best Coffee Beans To The World Market
        </motion.p>
      </div>
    </motion.div>
    
  )
}

export default AppWrap(Coffee, '', 'app__container app__coffee-bg')