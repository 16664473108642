//"@babel/plugin-proposal-private-property-in-object",
import React, { useState } from 'react'
import { motion } from 'framer-motion'
import images from '../../constants/images'
import { HiMenuAlt4, HiX } from 'react-icons/hi'
import './Navbar.css'

const Navbar = () => {
  const [Active, setActive] = useState('home');
  const [Toggle, setToggle] = useState(false);
  return (
    <div className='app'>
      <div className='app__logo'>
        <img src={images.logo} alt='logo' />
      </div>
      <div className='app__links'>
        
        {['home', 'services', 'export', 'construction', 'contact us'].map((item, index) => (
            <a key={"link-" + item} href={"#" + item} className={(Active === item) ? "active" : ""} onClick={() => {setActive(item);}}>  
              {item}
            </a>
        ))}
        
      </div>
      <div className='app__mobile-nav'>
        <div className='app__mobile-nav-menu'  onClick={() => {setToggle(true)}}>
        <HiMenuAlt4 />
        </div>
        {
          Toggle && (
          <motion.div whileInView={{x: [100, 0]}} className='app__mobile-nav-nav app__primarybg'>
            <div className='app__mobile-nav-close'>
              <HiX onClick={() => {setToggle(false)}}/>
            </div>
            <div className='app__mobile-nav-links'>
              {['home', 'services', 'export', 'construction', 'contact us'].map((item, index) => (
            <a key={"link-" + item} href={"#" + item} className={(Active === item) ? "active" : ""} onClick={(e) => {console.log(e);console.log(Active); setToggle(false);setActive(e.target.innerHTML);}}>  
              <li>{item}</li>
            </a>
        ))}
        </div>
          </motion.div>  
          )
        }

      </div>
    </div>
  )
}

export default Navbar