import React from 'react'
import { motion } from 'framer-motion'
import AppWrap from '../wrapper/AppWrap'
import './Skills.css'

const Skills = () => {
  return (
    <motion.div className='app__skills app__flex app__bg-dark' whileInView={{ opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
      
        <motion.h1 whileInView={{y: [-100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
          <span>Better Quality</span> <br></br>With <span>Better Service</span> <br></br> <span className='text__float-right'>For Sustainability</span>
        </motion.h1>
      
    </motion.div>
  )
}

export default AppWrap(Skills, '', 'app__container app__skills-bg ')