import React from 'react'
import { motion } from 'framer-motion'
import AppWrap from '../wrapper/AppWrap'

import './Const.css'

const Const = () => {
  return (
    <motion.div className='app__coffee app__flex app__bg-dark' whileInView={{opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
      <div className=' app__coffee-slog'>
        <motion.h1 whileInView={{y: [-100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
          <span>Construction</span> At it's Best 
        </motion.h1>
        <motion.p whileInView={{y: [100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
          Our company has been involved in all kind of construction works for more than a decade
        </motion.p>
      </div>
    </motion.div>
  )
}

export default AppWrap(Const, '', 'app__container app__const-bg')