import React from 'react'
import images from '../../constants/images'
import { motion } from 'framer-motion'
import AppWrap from '../wrapper/AppWrap'

import './About.css'

const About = () => {
  return (
    <div className='app__about'>
      <div className='app__about-container'>
      <motion.div className='app__about-description' whileInView={{x: [-100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
      <h1 className='title'>OUR <span>COMPANY</span><div /><div/></h1>
      <motion.p whileInView={{x: [-100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut', delayChildren : 0.5}}>
        Nushewer Engineering and trading is a company working in construction, Engineering and exporting business for 
        the past 10 years and has good experience reputation in delivering quality first products and services
      </motion.p>
      </motion.div>

      <motion.div whileInView={{x: [100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}} className='app__about-picture'>
      <img src={images.about} alt='about_company'/>
      </motion.div>
      </div>

      </div>

      
  )
}

export default AppWrap(About, 'home', 'app__container')