import React from 'react'
import { motion } from 'framer-motion'
import { Card } from '../../components'
import AppWrap from '../wrapper/AppWrap';
import { images } from '../../constants';

function ImportExport() {

  const coffee = images.coffee;
  const leather = images.leather;
  const aluminum = images.aluminum;

  const serviceItem = [
    {image: coffee, title: 'Coffee Export', list:[
        {name:'Green Been'},
        {name: 'Firist class coffee'}
      ]
    },
    {image: leather, title: 'Leather Products', list:[
      {name:'Quality products'},
      {name: 'Variety leather prodducts'}
    ]
    },
    {image: aluminum, title: 'Alumunium Foil Packaging', list:[
      {name:'Alumunium Foil'},
      {name: 'Packaging'}
    ]
    }
    ];

  return (
    <div className='app__service'>
      {/* <Title title ='Our Services'/> */}

      <motion.h1 className='title' whileInView={{y: [-100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
        Export <span>Items</span> <div /><div /> 
      </motion.h1>
      <div className='app__service-cards'>
        <Card details={serviceItem} />
      </div>

    </div>
  )
}

export default AppWrap(ImportExport, 'export', 'app__container app__graybg')