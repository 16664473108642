import React from 'react'
import { motion } from 'framer-motion'
import AppWrap from '../wrapper/AppWrap'
import { Card } from '../../components'
import images from '../../constants/images'

import './Service.css'

const imports = images.imports;
const exports = images.exports;
const construction = images.construction;
//https://www.youtube.com/redirect?event=video_description&redir_token=QUFFLUhqbDkxcG8yZXVxRjFXdmZZaDgwYVJYX0V5SEpMUXxBQ3Jtc0tsTlJhQ255dTMtSnBoYVdzSWpZMm5WeGxnc0oxY3JDem5kYjNyLVdvd3MxVzFHM3NpbVNFX2tZczhSLWMzMllrYjJHRjRNMmlfS0hJMXdmeHUwRC1QZVNnV2trdElEWk5JNGJHcWR3eTlrZVlQUzQtZw&q=https%3A%2F%2Fminhaskamal.github.io%2FDownGit%2F%23%2Fhome%3Furl%3Dhttps%3A%2F%2Fgithub.com%2Fadrianhajdin%2Fproject_professional_portfolio%2Ftree%2Fmaster%2Fsrc%2Fassets&v=3HNyXCPDQ7Q
const Service = () => {

  const serviceItem = [
    {image: imports, title: 'Import Goods', list:[
        {name:'Alumunium Foil'},
        {name: 'Construction Materials'}
      ]
    },
    {image: exports, title: 'Export Items', list:[
      {name:'Cooffee Export'},
      {name: 'Leather Products'}
    ]
    },
    {image: construction, title: 'Construction Works', list:[
      {name:'Building Construction '},
      {name: 'Road Construction'}
    ]
    }
    ];
  

  return (
    <div className='app__service'>
        {/* <Title title ='Our Services'/> */}
        
        <motion.h1 className='title' whileInView={{y: [-100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
          Our <span>Services</span> <div /><div /> 
        </motion.h1>

        <div className='app__service-cards'>
          <Card details = {serviceItem}/>
        </div>
    </div>
    
  )
}

export default AppWrap(Service, 'services', 'app__container app__graybg')