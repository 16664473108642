import React from 'react'
import { motion } from 'framer-motion'

const Card = (props) => {
  return (
    props.details.map((card, index) => (
      <motion.div key={'list-' + index} className='app__service-cards-item' whileInView={{y: [100, 0], opacity: [0, 1]}} whileHover={{y: [0, -20]}} transition= {{duration : 1, ease: 'easeIn'}} >
        <div className='app__service-cards-item-pic'>
            <img src={card.image} alt={"img-" + index} />
        </div>
        <div className='app__service-cards-item-description app__bg-dark'>
            <h3>{card.title}</h3>
            {card.list.map((item, index) => (
              <li key={'list' + index}>{item.name}</li>
            ))}
        </div>
    </motion.div>
    ))
  )
}

export default Card