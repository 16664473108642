import React from 'react'
import { motion } from 'framer-motion'
import { Card } from '../../components'
import AppWrap from '../wrapper/AppWrap';
import { images } from '../../constants';

import './Construction.css'

const Construction = () => {

  const building = images.building;
  const road = images.road;
  const bridge = images.bridge;
  const sewerage = images.sewerage;
  const water_works = images.water_works;

  const serviceItem = [
    {image: building, title: 'Building Construction', list:[
        {name:'Building Structure'},
        {name: 'Site Management'}
      ]
    },
    {image: road, title: 'Road Construction', list:[
      {name:'Road'},
      {name: 'Site Management'}
    ]
    },
    {image: bridge, title: 'Bridge Construction', list:[
      {name:'Bridge'},
      {name: 'Site Management'}
    ]
    },
    {image: sewerage, title: 'Sewerage Construction', list:[
      {name:'Sewerage'},
      {name: 'Site Management'}
    ]
    },
    {image: water_works, title: 'Water Works Construction', list:[
      {name:'Electro Mecanical work'},
      {name: 'Site Management'}
    ]
    }
    ];

  return (

    <div className='app__service'>
      {/* <Title title ='Our Services'/> */}

      <motion.h1 className='title' whileInView={{y: [-100, 0], opacity: [0, 1]}} transition= {{duration : 1, ease: 'easeInOut'}}>
        Construction <span>Works</span> <div /><div /> 
      </motion.h1>
      <div className='app__service-cards'>
        <Card details={serviceItem} />
      </div>

    </div>
  )
}

export default AppWrap(Construction, 'construction', 'app__container app__graybg')